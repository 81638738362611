const userMenu = [{
        title: "Dashboard",
        name: "Dashboard",
        icon: "dashboard",
        path: "/dashboard"
    },
    {
        title: "RecieveDID",
        name: "sip",
        icon: "dialer_sip",
        path: "/sip"
    },
    {
        title: "Rate List",
        name: "Rate List",
        icon: "list",
        path: "/ratelist"
    },
    {
        title: "Rate Upload",
        name: "Rate Upload",
        icon: "upload",
        path: "/rateUpload"
    },
    {
        title: "Cost Edit",
        name: "Cost Edit",
        icon: "paid",
        path: "/bulkCostEdit"
    },
    // {
    //     title: "Inbound Carrier",
    //     name: "Inbound Carrier",
    //     icon: "south_west",
    //     path: "/inboundCarrier"
    // },
    // {
    //     title: "Outbound Carrier",
    //     name: "Outbound Carrier",
    //     icon: "north_east",
    //     path: "/outboundCarrier"
    // },
    {
        title: "Carrier",
        name: "Carrier",
        icon: "code",
        path: "/carrier"
    },
    {
        title: "Channel",
        name: "Channel",
        icon: "live_tv",
        path: "/Channel"
    },
    // {
    //     title: "Ticket",
    //     name: "Ticket",
    //     icon: "theaters",
    //     path: "/ticket"
    // },
    {
        title: "Product Store",
        name: "Product Store",
        icon: "money",
        path: "/productstore"
    },
    {
        title: "Document Request",
        name: "Document Request",
        icon: "feed",
        path: "/documentRequest"
    },
    {
        title: "Live Call",
        name: "Live Call",
        icon: "swap_horiz",
        path: "/livecall"
    },
    {
        title: "DID Countries",
        name: "DID Countries",
        icon: "card_travel",
        path: "/didCountry"
    },
    {
        title: "Job Sandbox",
        name: "Job Sandbox",
        icon: "list",
        path: "/jobSandbox"
    },
    // {
    // 	title: "callflow",
    // 	name: "callflow",
    // 	icon: "shopphping_cart",
    // 	path: "/callflow"
    // },
    // {
    //     title: "Billing/Top Up",
    //     group: true,
    //     icon: "list",
    //     items: [{
    //             name: "Recharge",
    //             title: "Recharge",
    //             path: "/billingAcc",
    //             icon: "account_balance"
    //         },
    //         {
    //             name: "Transaction",
    //             title: "Transaction",
    //             path: "/transaction",
    //             icon: "credit_card"
    //         },
    //     ]
    // },
    // {
    //     title: "Launch DIDnPBX",
    //     name: "Launch DIDnPBX",
    //     icon: "phone",
    //     path: "/callflow"
    // },{
    //     title: "Number Manager",
    //     name: "Number Manager",
    //     icon: "shopping_bag",
    //     path: "/numberManager"

    // },{
    //     title: "Device",
    //     name: "Device",
    //     icon: "devices",
    //     path: "/device"
    // },
    
];

export default { userMenu };